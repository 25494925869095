<template>
  <v-row>
    <v-col offset="0" cols="12">
      <v-card>
        <v-card-title>
          <h3>Demo</h3>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validUploadForm">
            <v-row no-gutters class="justify-center" >
              <v-col >
                    <v-file-input
                      accept="video/*"
                      :rules="[
                        file => !!file || 'Needs to select a file',
                        file => !file || file.size < 100*1024*1024 || 'File size should be less than 100 MiB!',
                      ]"
                      label="Choose a video file"
                      filled
                      show-size
                      prepend-icon="mdi-video"
                      v-model="chosenFile"
                      :disabled="!!uploadedVideo || isUploading"
                      :loading='isUploading'
                      :error-messages='uploadError? uploadError.detail || uploadError.file: ""'
                      :messages="uploadedVideo? 'Uploaded!': ''"
                    >
                    </v-file-input>
              </v-col>
              <v-col cols='1'/>
              <v-col cols='2'>
                <v-btn x-large color="primary"
                :loading='isUploading'
                @click="fileUpload()"
                :disabled="!validUploadForm"
                v-if='!uploadedVideo'
                ><v-icon left dark>mdi-cloud-upload</v-icon>Upload</v-btn>
                <v-btn x-large color="error"
                @click="uploadedVideo=''; chosenFile=null;"
                :disabled="isComputing"
                v-if='uploadedVideo'
                ><v-icon dark x-large>mdi-delete</v-icon></v-btn>
              </v-col>
              <v-progress-linear :value='uploadProgress' color="primary" v-if='isUploading'/>
            </v-row>
          </v-form>

            <v-form v-if='!!uploadedVideo'>
              <v-card>
                <v-card-title>
                  <h4>Params</h4>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-slider
                        v-model="params.sampling_rate"
                        label="Sampling Rate"
                        thumb-label="always"
                        min=0.01
                        max=0.08
                        step=0.01
                      ></v-slider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer/>
                    <v-col cols='auto'>
                      <v-btn x-large color="primary"
                        :loading='isComputing'
                        @click='submitTask()'
                        ><v-icon left dark>mdi-movie-open-cog</v-icon>Process</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>


            <DemoResultComponent v-if='!!taskResult' :taskResult='taskResult' syncGroup="Demo"/>

            <v-alert
              v-if="!!computingError"
              dense
              type="error"
              border="left"
            >
              Processing task failed.
              <br/>
              {{computingError.detail}}
            </v-alert>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DemoResultComponent from './DemoResultComponent'
import axios from "axios"

export default {
  name: "DemoComponent",
  components: {DemoResultComponent},
  props: {
    //source: String
  },
  data() {
    return {
      validUploadForm: false,
      chosenFile: null,
      uploadedVideo: null,
      uploadError: {},
      uploadProgress: 0,
      params: {
        sampling_rate: 0.02
      },
      taskResult: null,
      isUploading: false,
      isComputing: false,
      computingError: null,
      conversionDone: false
    };
  },
  created() {},
  methods: {
    fileUpload() {
      let formData = new FormData();
      formData.append('file', this.chosenFile);
      const self = this;
      self.uploadProgress = 0;
      this.uploadError = {};
      this.isUploading = true;

      axios
          .post("/api/upload_video/",
          formData,
          {headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent) {
              self.uploadProgress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          }})
          .then(response => {
            this.uploadedVideo = response.data
          }).catch(err => {
            this.uploadError = err.response.data
            this.uploadedVideo = null;
          }).finally(() => {
            this.isUploading = false;
          })
    },
    submitTask() {
    this.isComputing = true
    this.conversionDone = false
    this.taskResult = null
    this.computingError = null
    axios
      .post(
        "/api/task/",
        {
          sampling_rate: this.params.sampling_rate,
          input_video: this.uploadedVideo.uid
        }
      ).then(response => {
        this.taskResult = response.data
        this.waitTaskFinished()
      }).catch(err => {
        this.computingError = err.response.data
        this.isComputing = false;
      }).finally(() => {
      })
    },
    waitTaskFinished() {
      axios
        .get(
          "/api/task/"+this.taskResult.uid
        ).then(response => {
          this.taskResult = response.data
          if(this.taskResult.status == 'DONE') {
            this.isComputing = false
            this.conversionDone = true
          } else if (this.taskResult.status == 'FAILED') {
            this.isComputing = false
            this.conversionDone = true
          } else {
            setTimeout(this.waitTaskFinished, 2000)
          }
        }).catch(err => {
          this.computingError = err.response.data
          this.isComputing = false;
        }).finally(() => {
        })
    }
  },
};
</script>

<style scoped>
</style>
