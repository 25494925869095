<template>
  <v-col cols='2' >
    <v-row no-gutters class="justify-center">
      <v-btn 
        fab x-large :href="path" target="_blank"
      >
        <v-icon x-large>{{ icon }}</v-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters class="justify-center">
      <big>{{ text }}</big>
    </v-row>
  </v-col>
</template>

<script>
export default {
  components: {},
  props: {
    icon: String,
    text: String,
    path: String
  }
};
</script>

<style scoped>
</style>