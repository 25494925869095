<template>
  <v-row>
    <v-col offset="2" cols="8">
      <v-card>
        <v-card-title primary-title class="justify-center">
          <h2>Extreme Video Compression and Completion</h2>
        </v-card-title>
        <v-card-text>
            <v-container>
            <v-row>
                <v-col>
                <h2>Inventors</h2> <br/>
                <v-row class="justify-center authors">
                    <v-col>
			<a href="https://majedelhelou.github.io/">Majed El Helou</a>
                    </v-col>
                    <v-col>
			<a href="https://zrfanzy.github.io/">Ruofan Zhou</a>
                    </v-col>
                    <v-col>
			<a href="https://datascience.ch/team_member/radhakrishna-achanta/">Radhakrishna Achanta</a>
                    </v-col>
                    <v-col>
                        <a href="https://www.epfl.ch/labs/ivrl/people/susstrunk/">Sabine Süsstrunk</a>
                    </v-col>
                </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col offset="0" cols="15">
                    <v-img
                    :src="require('../assets/adefan.png')"
                    class="my-3"
                    contain
                    />
                </v-col>
            </v-row>

            <v-row class="justify-center">
                <Icon icon="mdi-code-json" text="Licensing opportunity" path="https://drive.google.com/file/d/1DLL_2M9iaR3fdFVnrEtKpmqGid0e9RJV/view" />
                <v-col cols="3"/>
                <Icon icon="mdi-file-document-outline" text="Paper" path="http://infoscience.epfl.ch/record/277003/" />
            </v-row>

            <v-row>
                <v-col>
                <h2>Abstract</h2>
                <p class="abstract">
<br/>
Extreme image or video completion, where, for instance, we only retain 1% of pixels in random locations, enables very cheap sampling in terms of the required pre-processing. The transmitter requires no special hardware, close to no computation and energy consumption, and has absolute flexibility over the compression rate which can additionally be lowered to extreme rates. The consequence is, however, a reconstruction that is challenging for humans and inpainting algorithms alike. Our patent covers a state-of-the-art extreme video completion apparatus. We analyze a color motion estimation approach based on color KL-divergence that is suitable for extremely sparse scenarios. Our <b>ADEFAN</b> algorithm exploits the estimate to adapt between its spatial and temporal filtering when reconstructing the sparse randomly-sampled video. We validate our results on 50 publicly available videos using reconstruction PSNR and mean opinion scores. <br/> <br/>

			<b>This was supported by the Image and Visual Representation Lab (<a href="https://www.epfl.ch/labs/ivrl/">IVRL</a>), the EPFL Technology Transfer Office (<a href="https://www.epfl.ch/research/services/units/technology-transfer-office/">TTO</a>), and an <a href="https://www.epfl.ch/research/services/enable/">Enable</a> grant. The IP is listed <a href="https://www.epfl.ch/research/access-technology/available-ip/">online</a> and all the details (including contact information) can be found in the <a href="https://drive.google.com/file/d/1DLL_2M9iaR3fdFVnrEtKpmqGid0e9RJV/view">Licensing opportunity</a> document. </b> <br/>
For questions regarding the technology behind the patent or the latest software developments, please contact <a href="https://www.epfl.ch/labs/ivrl/people/susstrunk/">Professor Süsstrunk</a>.
                </p>
                </v-col>
            </v-row>

            

          
          

            </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Icon from './Icon';

export default {
  name: "PaperComponent",
  components: {Icon},
  props: {
    //source: String
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
h2 {
    word-break: keep-all;
    text-align: center;
}

.abstract {
    text-align: justify;
}

.authors {
    text-align: center;
}
</style>
