<template>
  <video ref="player" muted :controls="this.groupPrimary">
    <source ref="source" :src="src" type="video/mp4">
    Your browser does not support the video tag.
  </video> 
</template>

<script>
export default {
    props: {
        src: String,
        syncGroup: {
            type: String,
            default: ''
        },
        groupPrimary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            player: null,
            source: null
        }
    },
    mounted() {
        this.player = this.$refs.player;
        this.source = this.$refs.source;

        if(this.syncGroup) {
            if(this.groupPrimary) {
                this.player.addEventListener('play', () => {this.$root.$emit(this.syncGroup+'_play')})
                this.player.addEventListener('pause', () => {this.$root.$emit(this.syncGroup+'_pause')})
                this.player.addEventListener('seeking', () => {this.$root.$emit(this.syncGroup+'_seeking', this.player)})
            } else {
                this.$root.$on(this.syncGroup+'_play', () => {this.player.play()});
                this.$root.$on(this.syncGroup+'_pause', () => {this.player.pause()});
                this.$root.$on(this.syncGroup+'_seeking', (p) => {this.player.currentTime = p.currentTime});
            }
        }
    },
    watch: {
        src(newValue) {
            this.player.pause()
            this.source.setAttribute('src', newValue)
            this.player.load()
        },
        playing(newValue) {
            if (newValue) {
                this.player.play()
            } else {
                this.player.pause()
            }
        }
    }
}
</script>

<style scoped>
video {
    /* position: absolute; */
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
</style>