<template>
  <v-row>
    <v-col offset="0" cols="12">
      <v-card>
        <v-card-title>
          <h3>Sample Results (2% sampling)</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
                <v-col>
                    <v-select
                      solo
                      :items="items"
                      v-model="selected"
                    >
                      <template v-slot:messages>

                      </template>
                    </v-select>
                </v-col>
            </v-row>
            <DemoResultComponent v-if='!!selected' :taskResult='selected' syncGroup="Example" :isSample="true"/>

          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VideoComponent from './VideoComponent'
import DemoResultComponent from './DemoResultComponent'
import axios from "axios"

export default {
  name: "ExampleComponent",
  components: {DemoResultComponent},
  props: {
    //source: String
  },
  data() {
    let tmpPlaceholder = {text: 'Placeholder', value: {
        normalized_input:'',
        reconstructed_adefan:'test.mp4',
      }}
    return {
      items: [tmpPlaceholder],
      selected: tmpPlaceholder.value
    };
  },
  created() {},
  mounted() {
    axios
        .get(
          "/api/samples/"
        ).then(response => {
          let data = response.data
          this.items = response.data.map(d => {return {text:d.title, value:d}})
          let candidate = this.items.find((d)=> {return d.text=='Flower_Field2'})
          if(candidate)
            this.selected = candidate.value
          else
            this.selected = this.items[0].value
        }).catch(err => {
        }).finally(() => {
        })
  },
  methods: {},
};
</script>

<style scoped>
</style>