<template>
  <v-app>

    <v-main>
      <v-container>
        <PaperComponent/>

        <DemoComponent/>

        <ExampleComponent/>

      </v-container>
    </v-main>

    <v-footer
      app absolute
    >
      <span> {{ new Date().getFullYear() }} EPFL, IVRL </span>
      <v-spacer></v-spacer>
      <span></span>
    </v-footer>
  </v-app>
</template>

<script>
import PaperComponent from './components/PaperComponent';
import DemoComponent from './components/DemoComponent';
import ExampleComponent from './components/ExampleComponent';

export default {
  name: 'App',

  components: {
    PaperComponent, DemoComponent, ExampleComponent
  },

  data: () => ({
    //
  }),
};
</script>
