<template>
  <div>
    <v-row class="justify-center" v-if="!isSample">
      <v-col class='mt-4'>
      <h2>{{title}}</h2>
      <h5 v-if="subtitle"><i>{{subtitle}}</i></h5>
      </v-col>
    </v-row>
    <v-row class="justify-center" v-if='conversionDone' >
        <v-col cols="5">
          <h3>Input Video
            <!-- <v-btn x-small :href="this.taskResult.normalized_input" download><v-icon small>mdi-download</v-icon></v-btn> -->
            </h3>
          <VideoComponent :src="this.taskResult.normalized_input" :syncGroup='syncGroup' groupPrimary/>
        </v-col>
        <v-col cols="5">
          <h3>EFAN2D</h3>
          <VideoComponent :src="this.taskResult.reconstructed_efan2d" :syncGroup='syncGroup'/>
        </v-col>
        <v-col cols="5">
          <h3>ADEFAN</h3>
          <VideoComponent :src="this.taskResult.reconstructed_adefan" :syncGroup='syncGroup'/> 
        </v-col>
    </v-row>
    <v-row class="justify-center" v-if="isWaiting">
      <v-col cols='8' style="text-align: center;">
        <v-progress-circular indeterminate color='primary' size="300" width='20'/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VideoComponent from './VideoComponent'

export default {
  name: "DemoResultComponent",
  components: {VideoComponent},
  props: {
    taskResult: Object,
    syncGroup: String,
    isSample: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    conversionDone() {
      if (this.isSample){
        return true;
      }
      return this.taskResult.status == 'DONE';
    },
    isWaiting() {
      return (this.taskResult.status == 'PROCESSING') || (this.taskResult.status == 'IN_QUEUE');
    },
    title() {
      switch (this.taskResult.status) {
        case 'PROCESSING':
          return 'Status: processing...'
        case 'IN_QUEUE':
          return 'Status: in task queue...'
        case 'FAILED':
          return 'Processing failed.'
        default:
          return 'Results'
      }
    },
    subtitle() {
      switch (this.taskResult.status) {
        case 'PROCESSING':
        case 'IN_QUEUE':
          return "Processing might take some time on our server, thank you for your patience. The server is shared across multiple labs, if you encounter an issue (e.g. idle >10min) please contact the first or the last inventors."
        case 'FAILED':
          return 'Unfortunately our server could not process this video, maybe try with another one (longer than 8sec, but not excessively large resolution)?'
        default:
          return null
      }
    }
  },
  created() {},
};
</script>

<style scoped>
h3 {
  text-align: center;
}
</style>
